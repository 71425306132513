import { render, staticRenderFns } from "./ApplyResult.vue?vue&type=template&id=c55f7f22&scoped=true&"
import script from "./ApplyResult.vue?vue&type=script&lang=js&"
export * from "./ApplyResult.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/applyResult.less?vue&type=style&index=0&id=c55f7f22&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c55f7f22",
  null
  
)

export default component.exports