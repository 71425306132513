<template>
  <div class="wrap">
    <template>
      <el-backtop target=".layout-main" :right="11"/>
    </template>
    <div class="page-main">
      <div class="result">
        <dl>
          <dt class="successDt">
            <img
              src="@/assets/images/enterDone.png"
              alt=""
              class="successImg"
            />
            <p class="successText">
              {{applyTimeText[0].pdfInfo.title1}}！
            </p>
          </dt>
          <dd>
            <p>
              {{applyTimeText[0].pdfInfo.title2}}
            </p>
          </dd>
        </dl>
        <div class="image__preview">
          <el-row type="flex" class="row-bg" justify="center">
            <el-col :span="6"></el-col>
            <el-col :span="6">
              <el-image
                class="pic"
                :src="opusInfo.pdfThumbnailUrl"
                :preview-src-list="[opusInfo.pdfThumbnailUrl]"
              >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              </el-image>
            </el-col>
            <el-col :span="6" class="clList">
              <div class="contoralList">
                <div
                  @mouseenter="mouseEnter(1)"
                  @mouseout="mouseLeave(1)"
                  @click="downPackage('tsa')"
                >
                  <img :src="climg.img1" alt="" class="" />
                  <p v-html="applyTimeText[0].pdfInfo.title3"></p>
                </div>
                <div
                  @mouseenter="mouseEnter(2)"
                  @mouseout="mouseLeave(2)"
                  @click="lookDetail"
                >
                  <img :src="climg.img2" alt="" class="" />
                  <p>{{applyTimeText[0].pdfInfo.title4}}</p>
                </div>
                <div
                  @mouseenter="mouseEnter(3)"
                  @mouseout="mouseLeave(3)"
                  @click="$router.push({ name: 'ApplyTime' })"
                >
                  <img :src="climg.img3" alt="" class="" />
                  <p>{{applyTimeText[0].pdfInfo.title5}}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="followUp">
        <div class="hint">
          <h4>{{applyTimeText[1].SpeciaTips.title4}}</h4>
          <p v-html="applyTimeText[1].SpeciaTips.title1">
          </p>
          <p v-html="applyTimeText[1].SpeciaTips.title2">
          </p>
          <p v-html="applyTimeText[1].SpeciaTips.title3">
          </p>
        </div>
        <el-divider />
        <el-row type="flex" class="row-bg choseListApp" justify="center">
          <el-col :span="3" class="left">
            <img :src="choseListApp.img3" alt="" />
            <p>{{ $t('applyResult.externalLink.link1') }}</p>
          </el-col>
          <el-divider direction="vertical" style="86px"></el-divider>
          <el-col :span="$i18n.locale==='en'?17:14" class="right">
            <div>
              <i class="tsa-icon-monitor iconfont"></i>
              <el-button type="primary" plain round @click="goNext(5)"
                >{{ $t('applyResult.externalLink.link5') }}</el-button
              >
            </div>
            <div>
              <img :src="choseListApp.img2" alt="" class="img1"/>
              <!-- <p>{{ $t('applyResult.externalLink.link2') }}</p> -->
              <el-button type="primary" plain round @click="goNext(1)"
                >{{ $t('applyResult.externalLink.link2') }}</el-button
              >
            </div>
            <div>
              <img :src="choseListApp.img4" alt="" class="img2" />
              <!-- <p>{{ $t('applyResult.externalLink.link3') }}</p> -->
              <el-button type="primary" plain round @click="goNext(2)"
                >{{ $t('applyResult.externalLink.link3') }}</el-button
              >
            </div>
            <div>
              <img :src="choseListApp.img1" alt="" class="img3" />
              <!-- <p>{{ $t('applyResult.externalLink.link4') }}</p> -->
              <el-button type="primary" plain round @click="goNext(3)"
                >{{ $t('applyResult.externalLink.link4') }}</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import { findOpusInfo } from '@/api/service'
import { filterParams } from '@/utils/filter'
export default {
  data() {
    return {
      active: 3,
      serialNos: [],
      userinfo: {},
      opusInfo: {},
      climgList: {
        img1: require('@/assets/images/result/xiazai.png'),
        img2: require('@/assets/images/result/chakan.png'),
        img3: require('@/assets/images/result/shenqing.png'),
      },
      climgListA: {
        img1: require('@/assets/images/result/xiazaiA.png'),
        img2: require('@/assets/images/result/chakanA.png'),
        img3: require('@/assets/images/result/shenqingA.png'),
      },
      climg: {
        img1: require('@/assets/images/result/xiazai.png'),
        img2: require('@/assets/images/result/chakan.png'),
        img3: require('@/assets/images/result/shenqing.png'),
      },
      choseListApp: {
        img1: require('@/assets/images/result/lvshi.png'),
        img2: require('@/assets/images/result/diannao.png'),
        img3: require('@/assets/images/result/wechat.png'),
        img4: require('@/assets/images/result/tsa.png'),
      },
      isLength: false,
      yangLiJpg: require('@/assets/images/result/yangli.jpg'),
    }
  },

  components: {},

  computed: {
    VUE_APP_LAWCASE() {
      return (process.env.VUE_APP_LAWCASE +`?sourceType=952310`)
    },
    VUE_APP_QLWS() {
      return process.env.VUE_APP_QLWS
    },
    VUE_APP_EV() {
      return process.env.VUE_APP_EV
    },
    applyTimeText(){
      return[
        {
          pdfInfo: this.$t('applyResult.pdfInfo'),
        },
        {
          SpeciaTips: this.$t('applyResult.SpeciaTips'),
        },
      ]
    },
  },

  created() {
    this.serialNos = JSON.parse(sessionStorage.getItem('serialNos'))
    this.userinfo = this.$store.state.userInfo
    this.getInfo(JSON.parse(sessionStorage.getItem('serialNos')))
  },

  mounted() {},

  destroyed() {},

  methods: {
    lookDetail() {
      let obj = {
        serialNo: this.serialNos[0],
        page: 1,
        size: 10,
      }

      this.$router.push({
        path: '/time/FileDetail',
        query: filterParams(obj),
      })
    },
    goNext(type) {
      let url
      type === 1
        ? (url = process.env.VUE_APP_EV+'/vm_fetch')
        : type === 2
        ? (url = process.env.VUE_APP_QLWS)
        : type === 3
        ? (url = process.env.VUE_APP_LAWCASE+`?sourceType=952310`)
        : type === 5
        ? (url = process.env.VUE_APP_MONITOR)
        : ''
      window.open(url)
    },
    mouseEnter(type) {
      type === 1
        ? (this.climg.img1 = this.climgListA.img1)
        : type === 2
        ? (this.climg.img2 = this.climgListA.img2)
        : type === 3
        ? (this.climg.img3 = this.climgListA.img3)
        : ''
    },
    mouseLeave(type) {
      type === 1
        ? (this.climg.img1 = this.climgList.img1)
        : type === 2
        ? (this.climg.img2 = this.climgList.img2)
        : type === 3
        ? (this.climg.img3 = this.climgList.img3)
        : ''
    },
    async getInfo(data) {
      if (data.length > 1) {
        this.opusInfo = []
        let res = await findOpusInfo(data[0])
        this.opusInfo = res
      } else if (data.length === 1) {
        let res = await findOpusInfo(data)
        this.opusInfo = res
      }
      console.log(this.opusInfo)
    },
    async downPackage(str) {
      let obj = {
        serialNos: this.serialNos,
      }
      var baseURL
      if (process.env.NODE_ENV === 'development') {
        baseURL = '/api/api'
      } else {
        baseURL = '/opus/api'
      }
      window.open(
        baseURL +
          `/opus/downloadMultiFile?${qs.stringify(obj, { indices: false })}`
      )
    },
  },
}
</script>
<style src="../../assets/css/applyResult.less" lang="less" scoped></style>
